'strict'

import Web3 from 'web3'
// import BcaTokenContract from '../../api/truffle/abis/BancannabisToken.json'
import manasrCrowdfundingContract from '../../api/truffle/abis/EgroweedManasrPlants.json'
import dexbancannabis from '../../api/truffle/abis/SWAP-BNB-BCA.json'

export default (function() {
  const arrEnum = {
    cero: 0,
  }
  const get = async () => new Promise((resolve, reject) => {
    const lodWeb3 = async function() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum)
        // await window.ethereum.enable()
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider)
      } else {
        return false
      }

      return true
    }
    // Cargamos los contratos necesarios para el funcionamiento con metamask
    if (lodWeb3()) {
      try {
        const tokenObj = null
        const tokenBalance = null
        const tokenSupply = null
        let userAddress = ''
        let networkId
        let tokenData
        let dappTokenCrowdsaleData
        let dappDexBancannabis
        window.web3.eth.getAccounts()
          .then(accounts => {
            userAddress = accounts[arrEnum.cero]
          })
          .then(() => window.web3.eth.net.getId())
          .then(id => {
            networkId = id
            // tokenData = BcaTokenContract.networks[networkId]
          })
        // .then(() => new window.web3.eth.Contract(BcaTokenContract.abi, tokenData.address))
        // .then(token => {
        // tokenObj = token
        // return token.methods.balanceOf(userAddress).call()
        // })
        // .then(() => {
        // tokenBalance = tokenBalanc
        // tokenBalance = window.web3.utils.fromWei(tokenBalance.toString(), 'Ether')
        // return tokenObj.methods.totalSupply().call()
        // })
        // .then(tokenSuppl => {
        // tokenSupply = tokenSuppl
        // tokenSupply.toString()
        // })
          .then(() => {
            const contractMCC = manasrCrowdfundingContract
            dappTokenCrowdsaleData = contractMCC.networks[networkId]
            if (dappTokenCrowdsaleData) {
              return new window.web3.eth.Contract(contractMCC.abi, dappTokenCrowdsaleData.address)
            }
            return null
          })
          .then(async crowdsale => {
            const cMetadata = dexbancannabis
            dappDexBancannabis = cMetadata.networks[networkId]
            if (dappDexBancannabis) {
              return {
                dex: new window.web3.eth.Contract(cMetadata.abi, dappDexBancannabis.address),
                saleManasr: crowdsale,
              }
            }
            return null
          })
          .then(data => {
            resolve({
              web3: window.web3,
              tokenObj,
              dexContract: data.dex,
              saleContract: data.saleManasr,
              metamaskAddress: userAddress,
              tokenValues: {
                balance: tokenBalance,
                address: (tokenData) ? tokenData.address : '',
                totalSupply: tokenSupply,
              },
            })
          })
          .catch(err => {
            reject(new Error(err))
          })
      } catch (err) {
        reject(new Error('cant load web3'))
      }
    } else {
      reject(new Error('cant load web3'))
    }
  })

  const init = function() {
    return get()
      .then(contracts => contracts)
  }
  return {
    init,
  }
}())
