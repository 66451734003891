<template>
  <div>
    <!-- <vue-metamask
      v-if="doMetamaskRequest"
      @onComplete="onCompleteMetamask"
      @onNetworkChanged="onNetworkChangeMetamask"
    /> -->
    <!--
      <b-col
        cols="12"
        md="4"
        sm="12"
        style="text-align:center"
      > -->
    <!-- <earnings-chart :data="data" /> -->
    <!-- </b-col>
    </b-row> -->
    <b-row>
      <b-col
        cols="12"
        md="12"
        sm="12"
      >
        <b-row>
          <!-- <b-col
            cols="12"
            md="12"
            sm="12"
          >
            <bca-p2p />
          </b-col> -->
          <!-- <b-col
            cols="12"
            md="4"
            sm="12"
          >
            <b-card
              class="earnings-card"
              style="top: 1px; padding: 0px 0px; border: 1px #502f6d solid;"
            >
              <b-card-title class="mb-1">
                Take your business to the digital realm
              </b-card-title>
              <b-row>
                <b-col
                  cols="12"
                  style="padding: 10px;"
                >
                  <div class="font-small-2">
                    Create products for shipping and payments in your store, or configure customized packages with the exact quantity of units.<br>If you're a healthcare professional, you can also set copayments and manage your appointments on a scheduled basis.
                  </div>
                  <br>
                  <b-button
                    variant="outline-secondary"
                    class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                    style="border-color: #a6ce3f !important"
                    @click="goFabric()"
                  >
                    Create Store
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col> -->

          <b-col
            v-show="greenHousesPair[0].title == 'test'"
            cols="12"
            md="12"
            sm="12"
          >
            <b-card
              class="earnings-card"
              style="top: 1px; padding: 0px 0px; border: 1px #502f6d solid;"
            >
              <b-card-title class="mb-1">
                Dont have Pair data to show
              </b-card-title>

            </b-card>
          </b-col>
          <b-col
            v-for="(pair) in greenHousesPair"
            v-show="greenHousesPair[0].title !== 'test'"
            :key="pair.greenHouseId"
            cols="12"
            md="6"
            sm="12"
          >
            <!-- <b-card
              class="earnings-card"
              style="top: 1px; padding: 0px 0px; border: 1px #502f6d solid;"
            >
              <b-card-title class="mb-1">
                {{ pair.title }}
              </b-card-title>
              <b-row>
                <b-col
                  cols="12"
                  style="padding: 10px;"
                >
                  <div style="text-align: center;">

                    <b-row>
                      <b-col
                        cols="7"
                        style="padding: 10px;"
                      >
                        <div style="text-align: center;">
                          <amchart-pair-bca
                            :tokenaddress="pair.tokenAddress"
                            :greenhouseid="pair.greenHouseId"
                            :pairname="pair.title"
                            :setpairvalues="setPairValues"
                            :wallet="usrData.data.kyc.wallet"
                          />
                        </div>
                        <br><br>
                        <div style="text-align: center;">
                          <b>1 {{ pair.title.split('/')[0] }} = {{ proportionXToBCA[pair.greenHouseId] }} BCA</b>
                          <br>
                        </div>
                        <b-button
                          variant="outline-secondary"
                          class="btn-wishlist mt-1 mr-1 mr-sm-1 mb-1 mb-sm-0"
                          style="border-color: darkolivegreen !important"
                          @click="swapPair(pair.tokenAddress, pair.title, pair.greenHouseId)"
                        >
                          SWAP
                        </b-button>
                      </b-col>
                      <b-col
                        cols="5"
                        style="padding: 10px;"
                      >
                        <div style="text-align: center;">
                          <b>LP Pair Total Supply:</b>
                          <br>
                          {{ (lpTokenAmount[pair.greenHouseId]) ? lpTokenAmount[pair.greenHouseId] : 0 }} LP
                          <br><br>
                          <b>Total Reserve {{ pair.title.split('/')[0] }}:</b>
                          <br>
                          {{ (reserveXToken[pair.greenHouseId]) ? reserveXToken[pair.greenHouseId].toFixed(6) : 0 }}
                          <br><br>
                          <b>Total Reserve BCA:</b>
                          <br>
                          {{ (reserveBCA[pair.greenHouseId]) ? reserveBCA[pair.greenHouseId].toFixed(6) : 0 }}
                          <br>
                          <b-button
                            variant="outline-secondary"
                            class="btn-wishlist mt-1 mr-1 mr-sm-1 mb-sm-0"
                            style="border-color: darkolivegreen !important"
                            @click="addLiquidity(pair.tokenAddress, pair.title, pair.greenHouseId)"
                          >
                            Add Liquidity
                          </b-button>
                          <br><br>
                          <b>Yours LP Tokens:</b><br> {{ (lpBalance[pair.greenHouseId] > 0) ? lpBalance[pair.greenHouseId] : 0 }}<br>
                          <b-button
                            variant="outline-secondary"
                            class="btn-wishlist mt-1 mr-1 mr-sm-1 mb-sm-0"
                            style="border-color: tomato !important"
                            @click="removeLiquidity(pair.greenHouseId)"
                          >
                            Withdraw Liquidity
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card> -->
            <b-card
              class="earnings-card"
              style="border: 1px solid #502f6d; margin-bottom: 20px;"
            >
              <!-- Título del par -->
              <b-card-title class="mb-1" style="text-align: center; width:100%">
                <img
                  width="25px"
                  :src="pair.srcImgLogo"
                >
                {{ pair.title.split('/')[0] }}
                &nbsp;/&nbsp;
                <img
                  style="width:25px"
                  :src="require('@/assets/images/token/coin-logo.png')"
                >
                {{ pair.title.split('/')[1] }}
              </b-card-title>

              <!-- Contenido del card -->
              <b-row>
                <!-- Columna izquierda: Gráfica y proporción -->
                <b-col
                  cols="12"
                  md="6"
                  class="mb-1 mb-md-0"
                >
                  <div class="text-center">
                    <!-- Gráfica -->
                    <amchart-pair-bca
                      :tokenaddress="pair.tokenAddress"
                      :greenhouseid="pair.greenHouseId"
                      :pairname="pair.title"
                      :setpairvalues="setPairValues"
                      :wallet="usrData.data.kyc.wallet"
                    />
                    <!-- Proporción -->
                    <h5>
                      1 {{ pair.title.split('/')[0] }} = {{ proportionXToBCA[pair.greenHouseId] ? proportionXToBCA[pair.greenHouseId].toFixed(6) : 0 }} BCA
                    </h5>
                    <!-- Botón SWAP -->
                    <b-button
                      variant="outline-primary"
                      style="border-color: darkolivegreen;"
                      @click="swapPair(pair.tokenAddress, pair.title, pair.greenHouseId)"
                    >
                      <feather-icon
                        icon="RefreshCwIcon"
                        size="12"
                        class="mr-50"
                      />
                      SWAP
                    </b-button>
                  </div>
                </b-col>

                <!-- Columna derecha: Información de reservas y botones -->
                <b-col cols="12" md="6" class="mt-2">
                  <div class="text-center">
                    <!-- Fila 1: LP Pair Total Supply y Total Reserve X -->
                    <b-row>
                      <b-col cols="6">
                        <h6><b>Reserve {{ pair.title.split('/')[0] }}:</b></h6>
                        <p>{{ reserveXToken[pair.greenHouseId] ? reserveXToken[pair.greenHouseId].toFixed(6) : 0 }}</p>
                      </b-col>
                      <b-col cols="6">
                        <h6><b>Reserve BCA:</b></h6>
                        <p>{{ reserveBCA[pair.greenHouseId] ? reserveBCA[pair.greenHouseId].toFixed(6) : 0 }}</p>
                      </b-col>
                    </b-row>

                    <!-- Fila 2: Total Reserve BCA y Your LP Tokens -->
                    <b-row>
                      <b-col cols="6">
                        <h6><b>LP Pair Supply:</b></h6>
                        <p>{{ lpTokenAmount[pair.greenHouseId] || 0 }} LP</p>
                      </b-col>
                      <b-col cols="6">
                        <h6><b>Your LP Tokens:</b></h6>
                        <p>{{ lpBalance[pair.greenHouseId] > 0 ? lpBalance[pair.greenHouseId].toFixed(6) : 0 }}</p>
                      </b-col>
                    </b-row>

                    <!-- Botones: Add Liquidity y Remove Liquidity -->
                    <b-row class="mt-1">
                      <b-col cols="6">
                        <b-button
                          variant="outline-primary"
                          style="font-size: 12px;"
                          @click="addLiquidity(pair.tokenAddress, pair.title, pair.greenHouseId)"
                        >
                          <feather-icon icon="PlusCircleIcon" size="12" />
                          Add
                        </b-button>
                      </b-col>
                      <b-col cols="6">
                        <b-button
                          variant="danger"
                          style="font-size: 12px; border-color: tomato;"
                          @click="removeLiquidityBefore(pair.greenHouseId)"
                        >
                          Remove
                        </b-button>
                      </b-col>
                    </b-row>

                    <!-- Pair Info -->
                    <b-row class="mt-2">
                      <b-col cols="12">
                        <a
                          :href="`https://pancakeswap.finance/v2/add/${pair.tokenAddress}/0x4C6085438ed6D1aF4930644c370e90DaB811Be90`"
                          target="_blank"
                          class="btn btn-link"
                        >
                          View on PancakeSwap
                          <feather-icon icon="ArrowUpRightIcon" size="14" />
                        </a>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Modal add liquidity -->
    <b-modal
      ref="modalAddLiquidity"
      centered
      hide-footer
      ok-disabled
      ok-only
      ok-title="Add Liquidity"
      no-close-on-backdrop
      :title="`Please configure the liquidity ${ swapPairtitle }`"
    >
      <b-row>
        <b-col md="12">
          <validation-observer ref="formAddLiquidityValidation">
            <b-form>
              <b-form-group>
                <label>{{ swapPairtitle.split('/')[0] }} amount *</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Amount"
                >
                  <b-form-input
                    ref="xTokenAddLiquidityRef"
                    v-model="xTokenAddLiquidityAmount"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Type amount"
                    @keyup="onXTokenAmounBlur"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <br>
                <label>BCA Amount *</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="BCA Amount"
                >
                  <b-form-input
                    v-model="bcaAddLiquidityAmount"
                    readonly
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <br>
                <label>You Get LP Token</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Token Symbol"
                >
                  <b-form-input
                    v-model="lpTokenAddLiquidity"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-form>
          </validation-observer>
          <b-button
            variant="secundary"
            style="cursor:pointer; float:left"
            @click="closeTokenModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            style="cursor:pointer; float:right"
            @click="postAddLiquidity"
          >
            ADD
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal loading add liquidity -->
    <b-modal
      ref="modalLoadingAddSupply"
      centered
      ok-only
      hide-footer
      ok-disabled
      ok-title="Accept"
      no-close-on-backdrop
      title="Adding supply to token, please wait..."
    >
      <b-col>
        <b-progress
          animated
          centered
          :value="valueProgress"
          variant="success"
          class="'progress-bar-success'"
        />
        <span>Adding supply, please don't close this window...</span>
      </b-col>
    </b-modal>
    <!-- Modal Loading Swap -->
    <b-modal
      ref="modalLoadingSwap"
      centered
      ok-only
      hide-footer
      ok-disabled
      ok-title="Accept"
      no-close-on-backdrop
      title="Pair SWAP"
    >
      <b-col>
        <b-progress
          animated
          centered
          :value="valueProgress"
          variant="success"
          class="'progress-bar-success'"
        />
        <span>Swapping, please don't close this window...</span>
      </b-col>
    </b-modal>
    <!-- Modal Loading Withdraw Liquidity -->
    <b-modal
      ref="modalLoadingWithdrawLiquidity"
      centered
      ok-only
      hide-footer
      ok-disabled
      ok-title="Accept"
      no-close-on-backdrop
      title="Withdraw..."
    >
      <b-col>
        <b-progress
          animated
          centered
          :value="valueProgress"
          variant="success"
          class="'progress-bar-success'"
        />
        <span>Widthdraw in comming, please don't close this window...</span>
      </b-col>
    </b-modal>
    <!-- modal SWAP -->
    <b-modal
      ref="modalSwap"
      centered
      hide-footer
      ok-disabled
      ok-only
      ok-title="Swap Now"
      no-close-on-backdrop
      :title="`SWAP Pair ${ swapPairtitle }`"
    >
      <b-row>
        <b-col md="12">
          <validation-observer ref="formSwapValidation">
            <b-form>
              <b-form-group>
                <label><b>{{ swapPairtitle.split('/')[0] }}</b> Amount:</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="amount"
                >
                  <b-form-input
                    ref="xTokenSwapRef"
                    v-model="xTokenSwapAAmount"
                    :state="errors.length > 0 ? false:null"
                    placeholder="A Token amount"
                    @keyup="onXTokenSwapAAmounBlur"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <br>
                <div style="text-align:center; width: 100%">
                  <b-button
                    variant="outline-primary"
                    @click="tooglePair"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                    />
                  </b-button>
                  <br>
                  <label style="margin:0">Toggle Pair</label>
                </div>
                <!-- <label style="text-align:center; width: 100%">YOU GET</label> -->
                <label>You will get <b>{{ swapPairtitle.split('/')[1] }} </b>*</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="BCA Amount"
                >
                  <b-form-input
                    v-model="xTokenSwapBAmount"
                    readonly
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small>Price is approximate and subject to fees, liquidity, and slippage. Confirm the exact value on PancakeSwap.</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <b-button
            variant="secundary"
            style="cursor:pointer; float:left"
            @click="closeSwapModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            style="cursor:pointer; float:right"
            @click="postSwap"
          >
            SWAP
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      ref="modalConfirmWithdraw"
      centered
      cancel-title="Hold!"
      cancel-variant="outline-primary"
      ok-title="Withdraw"
      ok-variant="danger"
      title="Please confirm"
      @ok="removeLiquidity()"
      @cancel="cancelWithdraw()"
    >
      <b-col>
        <span>Do you want to remove liquidity and claim your LP token rewards?</span>
      </b-col>
    </b-modal>

    <app-tour :steps="steps" />
  </div>
</template>

<script>
import AmchartPairBca from '@core/components/charts/AmChartPairBCA.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  computed, reactive, toRefs, getCurrentInstance,
} from '@vue/composition-api'
// import CardAdvanceProfile from '@/views/cards/CardAdvanceProfile.vue'
// import ProjectShop from '@/views/e-project-list/e-project-shop/EProjectShop.vue'
// import ECommerceShop from '@/views/e-project-list/e-commerce-shop/ECommerceShop.vue'
import {
  BFormGroup,
  BForm,
  BFormInput,
  BProgress,
  BCard, BButton, BRow, BCol, BModal, BCardTitle, // BButton,
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
// import EarningsChart from './EarningsChart.vue'
// import BcaSwap from './BcaSwap.vue'
// import BcaP2p from './BcaP2P.vue'
// import eSeedStore from './home-store/home-nft-seed/ESeedStore.vue'
import contracts from '../libs/contract'

export default {
  components: {
    // eSeedStore,
    AppTour,
    BModal,
    BRow,
    BCol,
    // EarningsChart,
    // BcaSwap,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    // BCardText,
    BButton,
    BFormGroup,
    BForm,
    AmchartPairBca,
    BFormInput,
    BProgress,
    // BcaP2p,
    // CardAdvanceProfile,
    // ProjectShop,
    // ECommerceShop,
    // BButton,
  },
  setup() {
    // const lpBalance = ref([])
    // const reserveXToken = ref([])
    // const reserveBCA = ref([])
    // const sincChartData = ref([])
    // const proportionXToBCA = ref([])
    // const lpTokenAmount = ref([])
    const instance = getCurrentInstance()
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const state = reactive({
      reserveXToken: {},
      proportionXToBCA: {},
      lpTokenAmount: {},
      reserveBCA: {},
      sincChartData: {},
      lpBalance: {},
      currentTotalSupply: {},
    })

    const setPairValues = data => {
      state.reserveXToken[data.greenHouseId] = data.reserveXToken
      state.proportionXToBCA[data.greenHouseId] = data.proportionXToBCA
      state.lpTokenAmount[data.greenHouseId] = data.totalSupply.toFixed(2).toString()
      state.reserveBCA[data.greenHouseId] = data.reserveBCA
      state.sincChartData[data.greenHouseId] = data.sincData
      state.lpBalance[data.greenHouseId] = data.LPBalance
      state.currentTotalSupply[data.greenHouseId] = data.totalSupply

      instance.proxy.$forceUpdate()
    }

    return {
      skin,
      isDark,
      ...toRefs(state),
      setPairValues,
    }
  },
  data() {
    // setTimeout(() => {
    // Remove userData from localStorage
    // localStorage.removeItem('userData')
    // Redirect to login page
    // this.$router.push({ name: 'login' })
    // }, 5000)
    if (!localStorage.getItem('tourdone')) {
      setTimeout(() => {
        localStorage.setItem('tourdone', true)
        this.$tours.vuexyTour.start()
      }, 3000)
    }
    const sessionData = this.$sessionData.get()
    return {
      xTokenSwapBAmount: 0,
      xTokenSwapAAmount: 0,
      swapForBCA: false, // saber si tokenB es BCA
      // proportionXToBCA: [],
      // sincChartData: [],
      greenHouseId: 0,
      swapPairtitle: '',
      xTokenAmount: 0,
      // reserveBCA: [],
      // reserveXToken: [],
      // lpBalance: [],
      xTokenAddLiquidityAmount: 1,
      bcaAddLiquidityAmount: 0,
      lpTokenAddLiquidity: 0,
      // lpTokenAmount: [],
      BcaAmount: 0,
      valueProgress: 0,
      greenHousesPair: [
        { title: 'test' },
        // { greenHouseId: '672e584e4ef47325f5b9473d', title: 'TEST / BCA', tokenAddress: '0xAc4496Eb2d9EBd5831D13386aa0fFd194E1200eA' },
        // { greenHouseId: '672e584e4ef47325f5b9473d', title: 'TEST / BCA', tokenAddress: '0xAc4496Eb2d9EBd5831D13386aa0fFd194E1200eA' },
        // { greenHouseId: '672e584e4ef47325f5b9473d', title: 'TEST / BCA', tokenAddress: '0xAc4496Eb2d9EBd5831D13386aa0fFd194E1200eA' },
      ],
      steps: [
        {
          target: '#main-menu-navigation .TrelloIcon',
          header: {
            title: 'Trade tokens',
          },
          content: 'Trade tokens paired with BCA, you can swap tokens with BCA or add liquidity to the pair.',
        },
        {
          target: '#main-menu-navigation .ShoppingBagIcon',
          header: {
            title: 'Shop products and services',
          },
          content: 'You can buy products and services with BCA or other tokens.',
        },
        {
          target: '#main-menu-navigation .UserIcon',
          header: {
            title: 'User settings and wallet management',
          },
          content: 'Manage your wallet, see your balance, and configure your account settings.',
        },
      ],
      // data: {
      //   title: {
      //     text: 'Your history balance',
      //   },
      //   tooltip: {
      //     enabled: true,
      //   },
      //   series: [
      //     {
      //       data: [0, 0, 0],
      //       name: 'BCA deposits',
      //       dataLabels: {
      //         enabled: true,
      //       },
      //       labels: ['enero,feb,Marzo,abril,mayo'],
      //     },
      //     {
      //       data: [0, 0, 0],
      //       name: 'BCA outputs',
      //       color: 'red',
      //       dataLabels: {
      //         enabled: true,
      //       },
      //       labels: ['enero,feb,Marzo,abril,mayo'],
      //     },
      //   ],
      // },
      isGrowMaster: (!sessionData.isGm ? false : sessionData.isGm),
      usrData: null,
      doMetamaskRequest: false,
      contractPlantSale: null,
      web3: null,
      contractsDeployed: {
        metamaskAddress: '',
      },
      verifyCodeQuery: this.$route.query.vc, // verificar cuenta
      blockchainId: null,
      usrName: null,
      usrLastName: null,
      kycState: null,
      isZero: false,

      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    }
  },
  async created() {
    this.isZero = this.$route.query.iz === 'active'
    let relog = false
    // verificar un codigo (una cuenta)
    if (this.verifyCodeQuery !== '') {
      this.verifyCode()
    }
    // validar si la sesion esta activa
    // if (this.$sessionData.validToken()) {
    if (this.$sessionData.validateSession()) {
      // datos de usuario
      this.usrData = this.$sessionData.get()
      this.blockchainId = this.$sessionData.getCurrentNetworkData()
      try {
        // inicializar contrato si se ingreso con metamask
        if (this.usrData.metamaskSigin) {
          this.doMetamaskRequest = true
          this.contractsDeployed = await contracts.init().catch(() => false)
          // si devolvieron datos, asignar el contrato de venta de plantas
          if (this.contractsDeployed) {
            this.contractPlantSale = this.contractsDeployed.saleContract
            this.$sessionData.setMostGeneticsContractObject(this.contractPlantSale)
            // asignar libreria web3
            this.web3 = this.contractsDeployed.web3
          } else {
            this.contractsDeployed = {
              metamaskAddress: this.usrData.data.invitation.wallet,
            }
          }
        } else {
          this.contractsDeployed.metamaskAddress = null
        }
      } catch {
        relog = true
      }
    } else {
      relog = true
    }
    /*
    } else {
      relog = true
    }
    */
    if (relog) this.$router.push({ name: 'login', params: { iz: this.isZero } })

    // Get Home Market
    this.getPairMarket()

    return {
    }
  },
  methods: {
    // onCompleteMetamask(data) {
    //   if (!data.web3 || data.web3 === null || data.type === 'NO_LOGIN') {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Notification',
    //         icon: 'InfoIcon',
    //         text: data.message,
    //         variant: 'danger',
    //       },
    //     },
    //     {
    //       position: 'bottom-center',
    //       timeout: 5000,
    //     })
    //     this.$router.push({ name: 'login' })
    //   }
    // },
    // onNetworkChangeMetamask(data) {
    //   if (!data.web3 || data.web3 === null || data.type === 'NO_LOGIN') {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Notification',
    //         icon: 'InfoIcon',
    //         text: data.message,
    //         variant: 'danger',
    //       },
    //     },
    //     {
    //       position: 'bottom-center',
    //       timeout: 5000,
    //     })
    //     this.$router.push({ name: 'login' })
    //   }
    // },
    /**
     * Validar una cuenta de usuario
     */
    verifyCode() {
      if (this.verifyCodeQuery && this.verifyCodeQuery !== 'undefined') {
        this.$http.post(`/invitation/${this.verifyCodeQuery}/verify?nid=${this.blockchainId.id}`)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Your account has been verify successful',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 5000,
            })
          })
      }
    },
    goToUserSettings() {
      this.$router.push({ name: 'wallet' })
    },
    goToUserInventory() {
      this.$router.push({ name: 'my-genetic' })
    },
    goToMarketDefiHome() {
      this.$router.push({ name: 'market' })
    },
    goToMarketShop() {
      this.$router.push({ name: 'e-commerce-list' })
    },
    goToCompanyHome() {
      this.$router.push({ name: 'build-company' })
    },
    goToCannaverse() {
      window.open('https://bancannabis.org/cannaverse', '_blank').focus()
    },
    swapPair(address, title, greenHouseId) {
      this.swapPairtitle = title
      this.greenHouseId = greenHouseId
      this.xTokenSwapAAmount = 1
      this.onXTokenSwapAAmounBlur()
      this.showSwapModal()
    },
    tooglePair() {
      // debugger
      this.swapForBCA = !this.swapForBCA
      this.swapPairtitle = `${this.swapPairtitle.split('/')[1]} / ${this.swapPairtitle.split('/')[0]}`
      this.xTokenSwapAAmount = 1
      this.onXTokenSwapAAmounBlur()
    },
    cancelWithdraw() {
      this.greenHouseId = 0
    },
    removeLiquidityBefore(greenHouseId) {
      this.greenHouseId = greenHouseId
      this.$refs.modalConfirmWithdraw.show()
    },
    // confirm modal
    removeLiquidity() {
      // alert
      this.postWithdraw()
    },
    // MODAL ADD LIQUIDITY
    showAddLiquidityModal() {
      // this.creating = true
      this.$refs.modalAddLiquidity.show()
    },
    closeTokenModal() {
      // this.creating = true
      this.$refs.modalAddLiquidity.hide()
    },
    showAddSupplyLoadingModal() {
      // this.creating = true
      this.$refs.modalLoadingAddSupply.show()
    },
    closeAddSupplyLoadingModal() {
      this.$refs.modalLoadingAddSupply.hide()
    },
    // FIN MODAL ADD LIQUIDITY
    // MODAL SWAP
    showSwapModal() {
      // this.creating = true
      this.$refs.modalSwap.show()
    },
    closeSwapModal() {
      this.$refs.modalSwap.hide()
    },
    showSwapLoadingModal() {
      // this.creating = true
      this.$refs.modalLoadingSwap.show()
    },
    closeSwapLoadingModal() {
      this.$refs.modalLoadingSwap.hide()
    },
    // FIN MODAL SWAP
    // MODAL Loading Withdraw
    showWithdrawLiquidityLoadingModal() {
      this.$refs.modalLoadingWithdrawLiquidity.show()
    },
    closeWithdrawLiquidityLoadingModal() {
      this.$refs.modalLoadingWithdrawLiquidity.hide()
    },
    // fin modal loading withdraw

    async addLiquidity(tokenAddress, title, greenHouseId) {
      this.swapPairtitle = title
      this.greenHouseId = greenHouseId
      this.onXTokenAmounBlur()
      this.showAddLiquidityModal()
      // await this.getProportions(tokenAddress)
    },
    // setPairValues(data) {
    //   debugger
    //   this.lpTokenAddLiquidity = data.lpTokenAddLiquidity
    //   this.bcaAddLiquidityAmount = data.proportionXToBCA
    //   this.reserveXToken.value[data.greenHouseId] = data.reserveXToken
    //   this.proportionXToBCA.value[data.greenHouseId] = data.proportionXToBCA
    //   this.currentTotalSupply = data.totalSupply
    //   this.lpTokenAmount.value[data.greenHouseId] = data.totalSupply.toFixed(2).toString()
    //   this.reserveBCA.value[data.greenHouseId] = data.reserveBCA
    //   this.sincChartData.value[data.greenHouseId] = data.sincData
    //   this.lpBalance.value[data.greenHouseId] = data.LPBalance
    // },
    onXTokenAmounBlur() {
      // calcular LPs
      const lpFromTokenA = (this.xTokenAddLiquidityAmount / this.reserveXToken[this.greenHouseId]) * this.currentTotalSupply[this.greenHouseId]
      const lpFromTokenB = ((this.proportionXToBCA[this.greenHouseId] * this.xTokenAddLiquidityAmount) / this.reserveBCA[this.greenHouseId]) / this.reserveXToken[this.greenHouseId]
      this.bcaAddLiquidityAmount = (this.proportionXToBCA[this.greenHouseId] * this.xTokenAddLiquidityAmount).toFixed(2)
      this.lpTokenAddLiquidity = Math.min(lpFromTokenA, lpFromTokenB)
    },
    onXTokenSwapAAmounBlur() {
      // calcular el equivalente al intercambio
      if (this.swapForBCA) {
        this.xTokenSwapBAmount = this.xTokenSwapAAmount * this.proportionXToBCA[this.greenHouseId] // 1 XToken en BCA
      } else {
        this.xTokenSwapBAmount = this.xTokenSwapAAmount / this.proportionXToBCA[this.greenHouseId] // 1 BCA en XToken
      }
    },

    getPairMarket() {
      return new Promise((resolve, reject) => {
        try {
          // this.$http.defaults.headers['x-access-token'] = this.userData.token
          this.$http.get(`/greenhouse/0/pairmarket?nid=${this.$sessionData.getCurrentNetworkData().id}`)
            .then(async _result => {
              this.greenHousesPair = _result.data
            })
        } catch (e) {
          reject(e)
        }
      })
    },

    // getProportions(tokenAddress) {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       // this.$http.defaults.headers['x-access-token'] = this.userData.token
    //       this.$http.get(`/greenhouse/${this.greenHouseId}/pairProportion?tokenAddressA=${tokenAddress}&nid=${this.$sessionData.getCurrentNetworkData().id}`)
    //         .then(async _result => {
    //           this.currentPairAddress = _result.data.pairAddress
    //           this.currentReserveXToken = _result.data.reserveXToken
    //           this.currentReserveBCA = _result.data.reserveBCA
    //           this.currentProportionXToBCA = _result.data.proportionXToBCA
    //           this.bcaAddLiquidityAmount = this.currentProportionXToBCA // 1 XToken en BCA

    //           // calcular LPs
    //           const lpFromTokenA = (1 / this.currentReserveXToken) * this.currentTotalSupply
    //           const lpFromTokenB = (this.currentProportionXToBCA / this.currentReserveBCA) / this.currentReserveXToken
    //           this.lpTokenAddLiquidity = Math.min(lpFromTokenA, lpFromTokenB)
    //           this.currentProportionBCAToX = _result.data.proportionBCAToX
    //           this.currentTotalSupply = _result.data.totalSupply
    //           resolve(true)
    //         })
    //     } catch (e) {
    //       console.log(e)
    //       reject(e)
    //     }
    //   })
    // },
    postAddLiquidity() {
      return new Promise((resolve, reject) => {
        try {
          // validar el form
          this.$refs.formAddLiquidityValidation.validate().then(result => {
            if (result) {
              if (this.usrData.data.kyc) {
                if (!this.usrData.data.kyc.wallet) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'InfoIcon',
                      text: 'Please fill the KYC form to continue...',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'bottom-center',
                    timeout: 5000,
                  })
                  return
                }
              } else {
                return
              }

              // Cargar el modal de cargando y cerrar la modal con el form
              this.showAddSupplyLoadingModal()
              this.closeTokenModal()

              let progressIntervalToken
              // if (!this.creating) {
              // guardar fabric
              this.valueProgress += 20
              // eslint-disable-next-line prefer-const
              progressIntervalToken = setInterval(() => {
                if (this.valueProgress < 90) {
                  this.valueProgress += 2.5
                }
              }, 500)
              this.$http.defaults.headers['x-access-token'] = this.usrData.token
              // debugger
              this.$http.post(`/greenhouse/${this.greenHouseId}/addliquidity?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
                amountA: this.xTokenAddLiquidityAmount.toString(),
                amountB: this.bcaAddLiquidityAmount.toString(),
                wallet: this.usrData.data.invitation.wallet,
              })
                .then(async () => {
                  this.valueProgress = 100
                  // this.currentAddressCollection = args[0].data.contract
                  // this.$refs.wizardToken.finishButtonText = 'finish'
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'InfoIcon',
                      text: 'Your deposit has been successfully!',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'bottom-center',
                    timeout: 5000,
                  })
                  // console.log(_result)
                  if (this.sincChartData[this.greenHouseId]) {
                    this.sincChartData[this.greenHouseId]()
                  }
                  this.closeAddSupplyLoadingModal()
                  clearInterval(progressIntervalToken)
                  // this.$router.push({ name: '/' })
                  resolve(true)
                })
                .catch(err => {
                  this.valueProgress = 0
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: ':(',
                      icon: 'CloseIcon',
                      variant: 'warning',
                      text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
                    },
                  })
                  this.xTokenAddLiquidityAmount = 1
                  this.bcaAddLiquidityAmount = this.proportionXToBCA
                  this.closeAddSupplyLoadingModal()
                  clearInterval(progressIntervalToken)
                  reject()
                })
              // } else {
              //   // this.closeModal()
              //   clearInterval(progressIntervalToken)
              //   reject()
              // }
            }
          })
          resolve()
        } catch (e) {
          // console.log(e)
          reject(e)
        }
      })
    },
    postSwap() {
      return new Promise((resolve, reject) => {
        try {
          // validar el form
          this.$refs.formSwapValidation.validate().then(result => {
            if (result) {
              if (this.usrData.data.kyc) {
                if (!this.usrData.data.kyc.wallet) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'InfoIcon',
                      text: 'Please fill the KYC form to continue...',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'bottom-center',
                    timeout: 5000,
                  })
                  return
                }
              } else {
                return
              }

              // Cargar el modal de cargando y cerrar la modal con el form
              this.showSwapLoadingModal()
              this.closeSwapModal()

              let progressIntervalSwap
              this.valueProgress += 20
              // eslint-disable-next-line prefer-const
              progressIntervalSwap = setInterval(() => {
                if (this.valueProgress < 90) {
                  this.valueProgress += 2.5
                }
              }, 500)
              this.$http.defaults.headers['x-access-token'] = this.usrData.token
              // debugger
              this.$http.post(`/greenhouse/${this.greenHouseId}/swap?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
                isSell: this.swapForBCA, // Si se esta vendiendo XToken por BCA
                amountA: this.xTokenSwapAAmount.toString(), // cantidad del token A segun isSell
                amountB: this.xTokenSwapBAmount.toString(), // cantidad del token B segun isSell
                wallet: this.usrData.data.invitation.wallet, // firma
              })
                .then(async () => {
                  this.valueProgress = 100
                  this.bcaSwapAmount = 0
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'InfoIcon',
                      text: 'Swap has been successfully!',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'bottom-center',
                    timeout: 5000,
                  })
                  // console.log(_result)
                  if (this.sincChartData[this.greenHouseId]) {
                    this.sincChartData[this.greenHouseId]()
                  }
                  this.xTokenSwapBAmount = 0
                  this.xTokenSwapAAmount = 0
                  this.swapForBCA = false
                  this.closeSwapLoadingModal()
                  clearInterval(progressIntervalSwap)
                  // this.$router.push({ name: '/' })
                  resolve(true)
                })
                .catch(err => {
                  this.valueProgress = 0
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: ':(',
                      icon: 'CloseIcon',
                      variant: 'warning',
                      text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
                    },
                  })
                  this.xTokenSwapAAmount = 0
                  this.xTokenSwapBAmount = 0
                  this.closeSwapLoadingModal()
                  this.swapForBCA = false
                  clearInterval(progressIntervalSwap)
                  reject()
                })
            }
          })
          resolve()
        } catch (e) {
          // console.log(e)
          reject(e)
        }
      })
    },
    postWithdraw() {
      return new Promise((resolve, reject) => {
        try {
          if (this.usrData.data.kyc) {
            if (!this.usrData.data.kyc.wallet) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Please fill the KYC form to continue...',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-center',
                timeout: 5000,
              })
              return
            }
          } else {
            return
          }

          // Cargar el modal de cargando y cerrar la modal con el form
          this.showWithdrawLiquidityLoadingModal()
          let progressIntervalWithdrawLiquidity
          this.valueProgress += 20
          // eslint-disable-next-line prefer-const
          progressIntervalWithdrawLiquidity = setInterval(() => {
            if (this.valueProgress < 90) {
              this.valueProgress += 2.5
            }
          }, 500)
          this.$http.defaults.headers['x-access-token'] = this.usrData.token
          this.$http.post(`/greenhouse/${this.greenHouseId}/pairwithdraw?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
            wallet: this.usrData.data.invitation.wallet, // firma
          })
            .then(async () => {
              this.valueProgress = 100
              this.bcaSwapAmount = 0
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Withdraw has been successfully!',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 5000,
              })
              // console.log(_result)
              if (this.sincChartData[this.greenHouseId]) {
                this.sincChartData[this.greenHouseId]()
              }
              this.closeWithdrawLiquidityLoadingModal()
              clearInterval(progressIntervalWithdrawLiquidity)
              resolve(true)
            })
            .catch(err => {
              this.valueProgress = 0
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: ':(',
                  icon: 'CloseIcon',
                  variant: 'warning',
                  text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
                },
              })
              this.closeWithdrawLiquidityLoadingModal()
              clearInterval(progressIntervalWithdrawLiquidity)
              reject()
            })
          resolve()
        } catch (e) {
          reject(e)
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/tour.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  .modal-header .close {
    display:none;
  }
</style>
<style scoped>
.earnings-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-center h5, .text-center h6 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 1rem !important;
}
</style>
